import React, { useState, useRef } from "react";
// import ReactDOM from "react-dom";
import VideoPlayer from "./Components/VideoPlayer" 
import {ReactComponent as FbLogo } from "./assets/feedbacklogo.svg"

import "./style.css";

function App() {
  // React States
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [link, setLink] = useState('');

  const videoRef = useRef();
  var result;

  const errors = {
    uname: "invalid username or password",
  }; 

  const handleSubmit = (event) => {

    //Prevent page reload
    event.preventDefault();    
  
    var { uname, pass } = document.forms[0];

    var data = new FormData();
    data.append("user",uname.value);
    data.append("password", pass.value);


    fetch("https://bernd-lindberg.de/login", {method: "POST", mode:'cors', body: data}).then((res) => {
    
      result = res;

      res.text().then((text) => {
        setLink(text.slice(1,text.length-1));

    
        console.log("result", res);
        console.log("link:", link);

        if (result.status === 200) {
  
          setIsSubmitted(true);
        
        } else {
          // Username not found
          setErrorMessages({ name: "uname", message: errors.uname });
        }
       })

      }) 
  }
  

  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  // JSX code for login form
  const renderForm = (
    <div className="form">
      <div className="logo-container">    
        <FbLogo with="100px" height="100px"/>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <label>Username </label>
          <input type="text" name="uname" required />
          {renderErrorMessage("uname")}
        </div>
        <div className="input-container">
          <label>Password </label>
          <input type="password" name="pass" required />
          {renderErrorMessage("pass")}
        </div>
        <div className="button-container">      
          <input type="submit" value="submit"/>
        </div>
      </form>
    </div>
  );

  return (
    <div>

      <div className="app">
        
          {isSubmitted ? 
            <VideoPlayer videoLink={ link } pref={videoRef}/>
          :
            <div className="login-form"> 
              {renderForm}
            </div>
          }
      </div>
    </div>
  );
}

export default App;
