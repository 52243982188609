// import { useRef } from "react";
import React from "react";
import ReactHlsPlayer from 'react-hls-player'
const VideoPlayer=(props)=> {
    
  const { videoLink, ref } = props
  console.log('videoPlayer: ', videoLink);

    return(
        <div>
          <ReactHlsPlayer
            playerRef={ref}
            src={videoLink}
            autoPlay={true}
            controls={true}
            title=""
            width="100%"  
            height="auto"
          />
        </div>
      );
} 

export default VideoPlayer;

